import React from 'react';
import { useIntl } from 'react-intl';

interface NextArrowProps {
  altText?: string;
  ariaHidden?: boolean;
}

export const NextArrow = ({
  altText = '',
  ariaHidden = false,
}: NextArrowProps) => {
  const intl = useIntl();

  return (
    <svg
      aria-hidden={ariaHidden ? 'true' : 'false'}
      height="24"
      style={{ transform: 'rotateY(180deg)' }}
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.next' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>
  );
};
