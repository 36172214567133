import clsx from 'clsx';
import type { FC } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import CircularProgress from '../../components/CircularProgress';
import DesktopListItem from '../../components/desktop/ListItem';
import SearchableInput from '../../components/forms/SearchableInput';
import List from '../../components/List';
import LocationHeader from '../../components/LocationHeader';
import SpecificUserLanguagePreferences from '../../components/SpecificUserLanguagePreferences';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES, SHORTCUTS, USER_PREFERENCE } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { HeaderContext } from '../../contexts/HeaderContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { UsersContext } from '../../contexts/UsersContext';
import Item from '../../helpers/Item';
import Shortcuts from '../../helpers/Shortcuts';
import { URLtoCleanString } from '../../helpers/Url';
import { useSupportedLanguages } from '../../hooks/useSupportedLanguage';
import { useTranslatedLocaleString } from '../../hooks/useTranslatedLocaleString';
import { AnyoneAvailable, UserEndAdornment } from '../WalkInUsersHelper';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    background: theme.palette.white,
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    minHeight: '100%',
  },
  list: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
  },
}));

const searchFieldName = 'searchable-users';

interface WalkInUsersProps {
  next: (_selection?: object) => void;
  previous: (_selection?: object) => void;
  currentStep: number;
  stepsCount: number;
}

export const WalkInUsers: FC<WalkInUsersProps> = ({
  next,
  previous,
  currentStep,
  stepsCount,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles({ theme: useTheme() });
  const [query, setQuery] = useState('');
  const [preferences, setPreferences] = useState({});
  const [waitingForUsers, setWaiting] = useState(true);
  const [locale] = useContext(LocaleContext);
  const [, setHeader] = useContext(HeaderContext);
  const { kioskSelectPreferredLang } = useContext(SettingsContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const { data: supportedLanguages = null } = useSupportedLanguages();
  const { getTranslatedLocaleString } = useTranslatedLocaleString();
  const {
    loading: usersLoading,
    getCategoryUsers,
    getUsers,
    setState,
    users,
  } = useContext(UsersContext);

  const [{ location, settings, shortcuts, user, userCategory }, setSelections] =
    useContext(SelectionContext) as any;

  const hasPreferredUser = settings?.preferred_staff;

  useEffect(() => {
    if (userCategory && users === null) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getCategoryUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, waitingForUsers, userCategory, users]);

  // when not using shortcuts or if shortcut has no selections, fetch users for normal booking flow
  useEffect(() => {
    if (
      (shortcuts?.length === 0 || !Shortcuts.exists(SHORTCUTS.USER_CATEGORY)) &&
      !users
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    if (
      !Shortcuts.exists(
        SHORTCUTS.USER ?? Shortcuts.exists(SHORTCUTS.USER_CATEGORY),
      ) &&
      users?.length === 0
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    const handleBack = () => {
      const searchParams = new URLSearchParams(history.location.search);
      if (
        searchParams.has('preferred_staff_id') ||
        searchParams.has('preferred_lang')
      ) {
        searchParams.delete('preferred_staff_id');
        searchParams.delete('preferred_lang');
        history.push({
          search: URLtoCleanString(searchParams),
        });
      }

      setSelections({ userPreference: { id: null }, user: null });
      if (!kioskSelectPreferredLang) {
        previous();
      }
    };

    setHeader({
      action: handleBack,
      title: <FormattedMessage id="Steps.staff" />,
    });
  }, [history, kioskSelectPreferredLang, previous, setHeader, setSelections]);

  const getAriaLabel = (item) => {
    if (!kioskSelectPreferredLang) {
      return item.name;
    }

    const locales: string[] = spokenLanguages
      ? item.spokenLanguages
      : item.supportedLocales;

    const ariaLabelLanguages: string[] = locales.map((abbr) =>
      getTranslatedLocaleString(abbr),
    );

    // Outputs similar to {name} speaks {English, Dutch, French}
    return intl.formatMessage(
      { id: 'DateTime.locale_badge_label' },
      {
        name: item.name,
        language: ariaLabelLanguages.join(', '),
      },
    );
  };

  const getBadges = (item) => {
    if (!supportedLanguages) {
      return null;
    }

    const locales: string[] = spokenLanguages
      ? item.spokenLanguages.filter((lang) => supportedLanguages.includes(lang))
      : item.supportedLocales;

    return locales.map((abbr) => ({
      label: abbr,
    }));
  };

  const filteredUsers = useMemo(() => {
    const options = Item.map(preferences, (preference) =>
      preferences[preference] ? preference : null,
    ).filter(Boolean);

    const filtered =
      supportedLanguages?.length > 1 && options.length > 0
        ? users?.filter((user) =>
            spokenLanguages
              ? user.spokenLanguages.some((locale) => options.includes(locale))
              : user.supportedLocales.some((locale) =>
                  options.includes(locale),
                ),
          )
        : users;

    if (
      user &&
      !filtered?.find((person) => person.id === user.id) &&
      !usersLoading &&
      !waitingForUsers
    ) {
      setSelections({ user: null });
    }

    if (query.length < 2) {
      return filtered ?? [];
    }

    return (
      filtered?.filter((user) => user.name.toLowerCase().includes(query)) ?? []
    );
  }, [
    users,
    query,
    preferences,
    supportedLanguages,
    spokenLanguages,
    user,
    setSelections,
  ]);

  const handleSearch = ({ currentTarget: { value } }) => {
    setQuery(value.toLowerCase());
  };

  const setUser = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    if (usersLoading) {
      return;
    }

    if (user && user.id === id) {
      setSelections({ user: null });

      return;
    }

    const selection = {
      user: users?.find((item) => item.id === id),
      userPreference: { id: USER_PREFERENCE.SPECIFIC },
    };

    setSelections(selection);

    next(selection);
  };

  const showAllUsers = () => {
    if (usersLoading) {
      return;
    }

    if (users?.length && !usersLoading) {
      setState({ users: [], supportedLanguages: null });
      setSelections({
        settings: { ...settings, preferred_staff: null, sort: null },
        userCategory: null,
      });
    }
  };

  return (
    <section className={classes.root} data-testid="user-preference-mobile">
      <TrackPageView identifier={PAGES.USER_PREFERENCE} />
      <header aria-atomic="false" className="bg-white-100 p-5" role="alert">
        {location ? <LocationHeader location={location} /> : null}
        <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
        <Typography component="h1" variant="h5">
          <FormattedMessage id="UserPreference.select.header" />
        </Typography>
      </header>
      <section className="bg-white-100 flex flex-col gap-5 flex-grow overflow-hidden overflow-y-auto p-5 pt-0">
        <SearchableInput name={searchFieldName} onChange={handleSearch} />
        {kioskSelectPreferredLang && supportedLanguages?.length > 1 ? (
          <section className="mx-0.5">
            <SpecificUserLanguagePreferences
              languages={supportedLanguages}
              setPreferences={(preferences) => setPreferences(preferences)}
            />
          </section>
        ) : null}
        {waitingForUsers || usersLoading ? (
          <div className="py-16 px-8">
            <CircularProgress />
          </div>
        ) : users?.length ? (
          <section className={clsx(classes.list, 'flex-grow overflow-y-auto')}>
            <List id={`searchable-input-${searchFieldName}`}>
              {!kioskSelectPreferredLang ? (
                <AnyoneAvailable next={next} />
              ) : null}
              {filteredUsers?.map((item) => (
                <DesktopListItem
                  action={setUser}
                  adornment={
                    <UserEndAdornment
                      selected={!!user && item.id === user.id}
                    />
                  }
                  ariaLabel={getAriaLabel(item)}
                  badges={kioskSelectPreferredLang ? getBadges(item) : null}
                  id={item.id}
                  imageUrl={item.profilePhotoUrl}
                  key={item.id}
                  primary={item.name}
                  secondary={item.jobTitle}
                />
              ))}
            </List>
            {hasPreferredUser ? (
              <div className="flex justify-center p-6">
                <Button
                  fullWidth={false}
                  onClick={showAllUsers}
                  variant="smallOutlined"
                  {...({} as any)} // TypeScript doesn't recognize Button's props
                >
                  <FormattedMessage id="UserPreference.see_all_staff" />
                </Button>
              </div>
            ) : null}
          </section>
        ) : (
          <AnyoneAvailable next={next} />
        )}
      </section>
    </section>
  );
};
