export function isDigitSafe(notes: string | null | undefined = ''): boolean {
  if (notes === null || notes === undefined) {
    return true;
  }

  // Remove all special characters (all non alphanumeric characters)
  const cleanedInput = notes.replace(/[^0-9a-zA-Z]/g, '');

  // Regular expression to check for 8 or more consecutive digits
  const regex = /\d{8,}/;

  return !regex.test(cleanedInput);
}

interface Attendee {
  attributes?: {
    attendeeNotes?: string | null;
  };
}

export function attendeesHaveValidAttendeeNotes(
  attendees: Attendee[],
): boolean {
  for (const attendee of attendees) {
    if (!isDigitSafe(attendee.attributes?.attendeeNotes)) {
      return false;
    }
  }

  return true;
}
