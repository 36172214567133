import { Language } from '@coconut-software/ui/dist/icons';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router';
import { LOCALE_NAMES } from '../../shared/constants';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import { GOOGLE_CLIENT_STORAGE_KEY } from '../constants';
import { HeaderContext } from '../contexts/HeaderContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import {
  MOBILE,
  ViewModeContext,
  isSmallScreen,
} from '../contexts/ViewModeContext';
import Item from '../helpers/Item';
import Languages from '../helpers/Languages';
import Storage from '../helpers/Storage';
import { URLtoCleanString } from '../helpers/Url';
import DropDownArrow from './icons/DropDownArrow';
import SelectableListItem from './SelectableListItem';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  anchor: {
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '0.5rem 0.75rem',
    textDecoration: 'none',
    '&:focus': {
      background: theme.palette.neutral[100],
    },
    '&:hover': {
      background: theme.palette.neutral[100],
    },
  },
  avatar: {
    border: 'none',
    borderRadius: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
    height: '2rem',
    width: '2rem',
  },
  customRoot: {
    paddingRight: 0,
    marginRight: '1rem',
    marginLeft: 'auto',
    width: 'auto',
  },
  google: {
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.1);',
    display: 'inline-flex',
    padding: '0.75rem 0.875rem 0.75rem 0.875rem',
    width: '100%',
  },
  googleEmail: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  header: {
    paddingLeft: '0.75rem',
  },
  hidden: {
    display: 'none',
  },
  icon: {
    background: theme.palette.white,
    borderRadius: '0.125rem',
    display: 'flex',
    padding: { right: '0.625rem' },
    alignItems: 'center',
    '& svg': {
      width: '1.25rem',
    },
  },
  languageButton: {
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
  },
  languageIcon: {
    '& svg': {
      fill: theme.palette.secondary[400],
    },
  },
  mobile: {
    maxWidth: '90vw',
    overflow: 'hidden',
  },
  open: {
    display: 'block',
  },
  popperIndex: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.hover,
    fontFamily: theme.fontFamilies.sansSerif,
    fontSize: theme.textSizes.base,
    position: 'absolute',
    right: '0',
    top: '100%',
    width: 'auto',
    zIndex: 9999,
    height: 'fit-content',
    overflow: 'scroll',
    maxHeight: '60vh',
  },
  signOut: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'rgba(0, 0, 0, 0.1);',
  },
  title: {
    cursor: 'pointer',
    padding: {
      left: '1.825rem',
      right: '0.5rem',
      top: '0.5rem',
      bottom: '0.5rem',
    },
  },
  localeLabel: {
    textTransform: 'capitalize',
  },
}));

const LanguagePicker = () => {
  const classes = useStyles({
    theme: useTheme(),
    isSmallScreen: isSmallScreen(),
  });
  const intl = useIntl();
  const mode = useContext(ViewModeContext);
  const [{ attendee, googleUser }, setSelections] =
    useContext(SelectionContext);
  const [locale, setLocale] = useContext(LocaleContext);
  const { availableLanguages } = useContext(SettingsContext);
  const [{ action }] = useContext(HeaderContext);
  const { shouldUseKioskUXEnhancements } = useContext(FeatureDecisionContext);
  const isMobile = mode === MOBILE && action;
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const onSelectLanguage = (lang) => {
    if (lang === locale) {
      return;
    }

    Languages.setCurrent(lang);
    setLocale(lang);

    searchParams.set('lang', lang);

    history.push({ search: URLtoCleanString(searchParams) });
    setSelections({ language: lang, search: URLtoCleanString(searchParams) });
  };

  if (availableLanguages && availableLanguages.length <= 1) {
    return <div data-testid="empty-picker" />;
  }

  const currentLanguage = (
    <>
      <Typography
        classes={{ root: classes.localeLabel }}
        component="div"
        data-testid="current-language"
        primary
        variant="caption1"
      >
        {isSmallScreen() ? locale : LOCALE_NAMES[locale]}
      </Typography>
      <DropDownArrow
        altText={intl.formatMessage({ id: 'Svg.alt_text.select_language' })}
      />
    </>
  );

  const signOut = () => {
    Storage.clearPermanent(GOOGLE_CLIENT_STORAGE_KEY);

    setSelections({
      attendee: googleUser.prefill ? null : attendee,
      googleUser: null,
    });
  };

  const languageIcon =
    searchParams.has('mode', 'kiosk') && shouldUseKioskUXEnhancements ? (
      <div
        className={classNames(classes.languageIcon, 'mr-2', 'w-6', 'h-6')}
        data-testid="language-icon"
      >
        <Language />
      </div>
    ) : null;

  return (
    <Listbox
      className={classNames(classes.root, isMobile && classes.customRoot)}
      data-testid="picker"
      onChange={onSelectLanguage}
      value={locale}
    >
      {({ open }) => (
        <div>
          <Listbox.Button>
            <div className={classes.anchor}>
              {languageIcon}
              {googleUser ? (
                <>
                  <div className={classes.avatar}>
                    <img alt={googleUser.email} src={googleUser.avatar} />
                  </div>
                  <DropDownArrow
                    altText={intl.formatMessage({
                      id: 'Svg.alt_text.select_language',
                    })}
                  />
                </>
              ) : (
                currentLanguage
              )}
            </div>
          </Listbox.Button>
          <Listbox.Options>
            <div
              className={classNames(
                open ? classes.open : classes.hidden,
                classes.popperIndex,
                isMobile && classes.mobile,
              )}
            >
              {googleUser ? (
                <div className={classes.google}>
                  <div className={classes.icon}>
                    <svg
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 256 262"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                        fill="#4285F4"
                      />
                      <path
                        d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                        fill="#34A853"
                      />
                      <path
                        d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                        fill="#FBBC05"
                      />
                      <path
                        d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                        fill="#EB4335"
                      />
                    </svg>
                  </div>
                  <Typography
                    classes={{ root: classes.googleEmail }}
                    grey
                    variant="body2"
                  >
                    {googleUser.email}
                  </Typography>
                </div>
              ) : null}
              {Item.map(availableLanguages, (language) => (
                <SelectableListItem
                  checked={language.abbr === locale}
                  key={language.abbr}
                  lang={language.abbr}
                  title={LOCALE_NAMES[language.abbr]}
                  value={language.abbr}
                />
              ))}
              {googleUser ? (
                <div
                  className={classNames(classes.anchor, classes.signOut)}
                  onClick={signOut}
                  onKeyUp={signOut}
                  role="button"
                  tabIndex={0}
                >
                  <Typography
                    classes={{ root: classes.title }}
                    variant="subtitle"
                  >
                    <FormattedMessage id="Ui.sign_out" />
                  </Typography>
                </div>
              ) : null}
            </div>
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export default LanguagePicker;
