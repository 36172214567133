import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { Anchor } from './Anchor';
import CoconutLogo from './CoconutLogo';
import Typography from './Typography';

const useStyles = createUseStyles({
  root: {
    paddingTop: '1.25rem',
  },
  link: {
    textDecoration: 'none',
  },
});

const Footer = ({ classes: injectedClasses, component }) => {
  const classes = { ...useStyles(), ...injectedClasses };

  const { privateLabel } = useContext(SettingsContext);
  const history = useHistory();
  const { shouldDisableKioskCocoLink } = useFeatureDecisions();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  // privateLabel is undefined on initial render which is falsy,
  // so we must use exactly false to not display it until we have the value
  const showLogo = privateLabel === false;
  if (!showLogo) {
    return null;
  }

  const disableLink =
    shouldDisableKioskCocoLink && searchParams.has('mode', 'kiosk');

  const contents = (
    <>
      <Typography component={component}>
        <FormattedMessage id="Sidebar.footer" />
      </Typography>
      <CoconutLogo />
    </>
  );

  return (
    <footer className={classes.root} data-testid="sidebar-footer">
      {disableLink ? (
        <div className="p-3">{contents}</div>
      ) : (
        <Anchor
          external
          href="https://www.coconutsoftware.com/?utm_source=coconut-product&utm_medium=coconut-client-view&utm_campaign=coconut-logo"
          type="area"
          variant="title"
        >
          {contents}
        </Anchor>
      )}
    </footer>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  component: PropTypes.string,
};

Footer.defaultProps = {
  classes: {},
  component: 'p',
};

export default Footer;
